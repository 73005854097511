import Carousel from '@sdtech/sd-ui/dist/commonjs/components/Carousel/Carousel';
import React, { FunctionComponent } from 'react';
import AiBanner from '../banners/aiBanner/AiBanner';
import ClimateBanner from '../banners/climateBanner/ClimateBanner';
import ResearchConfidenceBanner from '../banners/researchConfidenceBanner/ResearchConfidenceBanner';

interface HomepageCarouselProps {
    assetsBaseUrl: string;
}

const HomepageCarousel: FunctionComponent<HomepageCarouselProps> = ({
    assetsBaseUrl,
}) => (
        <Carousel
          id="bannerCarouselContent"
          className="carousel"
          content={[
              // eslint-disable-next-line react/jsx-key
              <AiBanner assetsBaseUrl={assetsBaseUrl} />,
              // eslint-disable-next-line react/jsx-key
              <ResearchConfidenceBanner assetsBaseUrl={assetsBaseUrl} />,
              // eslint-disable-next-line react/jsx-key
              <ClimateBanner assetsBaseUrl={assetsBaseUrl} />,
          ]}
          indicators={[
              'ScienceDirect AI',
              'Confidence in research',
              'Climate change',
          ]}
        />
    );

export default HomepageCarousel;
